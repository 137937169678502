@import "../variables.less";


// Logic
.navigation-for-mobile {

    /*
    .mobile { display: block; }
    .non-mobile { display: none; }
    */

    .navigation-oo-19798 .first-menu,
    .navigation-oo-19798 .first-menu .side {
        flex-direction: column;
    }
    .mobile-hide {
        display: none;
    }

    &[class*="first-menu-"] .first-menu {
         display: none;
    }
    &.blogs-special-menu  .first-menu {
        display: none;
    }
    &.first-menu-about-us .item.about-us {
        display: none;
    }
    &.blogs-special-menu .item.blogs {
        display: none;
    }
}

// Reordering items
.navigation-oo-19798 {

    .second-menu.for-home {

        & > :nth-child(1) {
            order: 1;
        }
        & > :nth-child(2) {
            order: 3;
        }
        & > :nth-child(3) {
            order: 2;
        }
    }

    .second-menu.for-business {

        & > :nth-child(1) {
            order: 1;
        }
        & > :nth-child(2) {
            order: 3;
        }
        & > :nth-child(3) {
            order: 2;
        }
    }
}

// Overlay when navigation is opened
body.navigation-for-mobile:not(.navigation-closed)::before {
    display: block;
    position: fixed;
    z-index: 900;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: @Grey-0-new;
    opacity: 0.8;
}

// active menu colors
.second-menu-products .second-menu .products,
.second-menu-security .second-menu .security,
.second-menu-privacy .second-menu .privacy,
.second-menu-performance .second-menu .performance,
.second-menu-family .second-menu .family,
.second-menu-bundles .second-menu .bundles,
.second-menu-business-partners .second-menu .business-partners,
.second-menu-resources .second-menu .resources,
.second-menu-press-center .second-menu .press-center,
.second-menu-investors .second-menu .investors {
    background-color: @White;

     &.arrow {
        background-image: url(../../../i/navigation/arrow-collapse.svg) !important;
     }
}
.navigation-regions-0 .regions-0,
.navigation-regions-1 .regions-1,
.navigation-regions-2 .regions-2 {
    background-color: @White;

     .subcategory.arrow {
        background-image: url(../../../i/navigation/arrow-collapse.svg) !important;
     }
}

// lets make a space for login buttons
.second-menu-login .second-menu.for-business {
    .mobile-links-top {
        padding-bottom: 40px;
    }
}


// navigation closed
.navigation-closed {

    .navigation-oo-19798 {
        .first-menu,
        .second-menu {
            display: none !important;
        }
        .toggle-menu {
            background-image: url(../../../i/navigation/burger.svg);

            span {
                display: none;
            }
        }
    }
}


// mobile header height
@mobile-header-height: 64px;

// make margin on the following element
/*
header + * {
    margin-top: @mobile-header-height !important;
}
*/

body {
    padding-top: @mobile-header-height !important;
}

/* Mobile navigation styles */
.navigation-oo-19798 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;

    &::before {
        content: "";
        display: block;
        background-color: @White;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: @mobile-header-height;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    // logo
    .avast-logo {
        position: absolute;
        top: 13px;
        left: 16px;
        z-index: 1010;
    }

    // search
    .search {
        position: absolute;
        top: 0;
        right: @mobile-header-height;
        width: @mobile-header-height;
        height: @mobile-header-height;
        background: transparent url(../../../i/navigation/search.svg) 50% 50% no-repeat;
        cursor: pointer;

        span {
            display: none;
        }
    }

    // hamburger
    .toggle-menu {
        position: absolute;
        top: 0;
        right: 0;
        width: @mobile-header-height; // it's a square
        height: @mobile-header-height;
        background-color: @Grey-9;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-image: url(../../../i/navigation/cross-big.svg);
        cursor: pointer;


        span {
            position: absolute;
            left: 0;
            bottom: 5px;
            display: block;
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            font-size: 8px;
            color: #aaabb6;
        }
    }

    // mixins

    // navigation icon
    .navigation-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
    }

    // navigation tree
    nav {
        margin-left: 25px; // space to see the content below
        padding-top: @mobile-header-height;
    }

    // first menu
    .first-menu,
    .mobile-bottom-menu {

        ul {
            li {
                position: relative;
                padding: 20px 60px 20px 20px;
                background-color: @Grey-9;
                background-image: url(../../../i/navigation/arrow-big.svg);
                background-position: top 50% right 20px;
                background-repeat: no-repeat;
                cursor: pointer;

                // separator
                &::after {
                    display: block;
                    content: "";
                    position: absolute;
                    left: 20px;
                    bottom: 0;
                    width: calc(100% - 40px;);
                    height: 1px;
                    background-color: @Grey-8;
                }
                &.for-partners::after,
                &.about-us::after {
                    display: none;
                }
                &.blogs::after {
                    background-color: @Grey-7;
                }

                // darker items
                &.about-us,
                &.blogs,
                &.region {
                    background-color: @Grey-8;
                }

                // category label
                .category {
                    display: block;
                    font-size: 16px;
                    color: @Grey-0-new;
                    font-weight: 400;
					padding-bottom: 5px;
                }
                .text {
                    font-size: 12px;
                }
            }
        }
    }


    // second menu - bottom row of header
    .second-menu {
        flex-direction: column;
        background-color: @Grey-9;

        // temporary, will be prolly replaced by other element

        // backlink
        .back {
            padding: 25px 34px 25px 58px;
            background: @Grey-9 url(../../../i/navigation/arrow-return.svg) 25px 50% no-repeat;
            color: @Grey-0-new;
            font-size: 20px;
            font-weight: normal;
        }

        ul {
            li {
                cursor: pointer;
            }
        }

        // subcategory label
        .subcategory {
            display: block;
            font-size: 16px;
            font-weight: 400;
            color: @Grey-0-new;
        }
        ul:not(.mobile-links-top) {

            .subcategory {
                position: relative;
                padding: 20px 60px 20px 40px;

                // this means that it can be toggled
                &.arrow {
                    background-image: url(../../../i/navigation/arrow-inactive.svg);
                    background-position: top 50% right 20px;
                    background-repeat: no-repeat;
                }
            }
        }

        // first block on mobile menu has special styles with icons etc
        .mobile-links-top {
            position: relative; // because of login buttons
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-left: 17px;
            padding-right: 17px;
            background-color: @Grey-9;

            li {
                background-color: transparent;

                .subcategory {
                    text-transform: uppercase;
                    padding: 0 15px 20px;
                    text-align: center;
                    font-size: 10px;
                    color: #767683;
                    // background: transparent url(../../../i/navigation/arrow-inactive.svg) 50% 10px no-repeat;

                    img {
                        display: block;
                        margin: 0 auto 10px;
                    }
                }
            }
        }

        // blogs
        &.blogs {

            li:not(.back) {
                padding: 20px 60px 20px 20px;
                position: relative;
                background: @White url(../../../i/navigation/arrow-big.svg) top 50% right 20px no-repeat;

                &:not(:last-child)::after {
                    content:"";
                    display: block;
                    position: absolute;
                    left: 20px;
                    bottom: 0;
                    background-color: @Grey-8;
                    width: calc(100% - 40px);
                    height: 1px;
                }

                &:last-child {
                    border-bottom: none;
                }

                span {
                    display: block;

                    &.txt-blogs-title {
                        font-size: 20px;
                        padding-bottom: 10px;
                        color: @Grey-0-new;
                    }
                    &.txt-blogs-description {
                        font-size: 12px;
                    }
                }
            }

        }

        // region selector
        &.regions {

            .custom-regions {
                display: flex;
                flex-direction: column;
            }

            .subcategory {
                font-size: 16px;
                font-weight: normal;
                color: @Grey-0-new;
                padding: 20px;

                // this means that it can be toggled
                &.arrow {
                    background-image: url(../../../i/navigation/arrow-inactive.svg);
                    background-position: top 50% right 20px;
                    background-repeat: no-repeat;
                }
            }

            .main-regions {

                li {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
            .area {

                ul:last-child {
                    padding-bottom: 20px;
                }
                li {
                    padding-left: 40px;
                    padding-right: 20px;
                }
            }
        }
    }

    // third menu
    .third-menu {

        .name {
            display: block;
            white-space: nowrap;
            padding: 7px 0 7px;
            font-size: 14px;
            line-height: 28px;
        }

        // all items with this class has arrow
        .mobile-link {

            &:last-of-type {
                padding-bottom: 20px;
            }

            /*
            // currently it should be removed
            &::after {
                content: url(../../../i/navigation/arrow-item-lighter.svg);
                display: inline-block;
                margin-left: 10px;
                vertical-align: middle;
            }
            */
        }

        // product - has spacific font
        .block-products {

            & > li {
                padding: 0 40px;
                font-size: 14px;

                &:last-child {
                    padding-bottom: 20px;
                }

                .logo {
                    margin-right: 7px;
                    vertical-align: middle;
                }

                .os,
                .description {
                    display: none;
                }
            }
        }

        // third menu - hint (sentence below the products)
        .hint {
            padding: 14px 40px;
            font-size: 12px;
            text-align: center;

            &.desktop {
                display: none; // hide desktop hints
            }
        }

        // section specifics
        &.security {

            // hint with platform selection
            .hint {
                display: block;
                position: relative;
                padding: 0 34px 25px;

                .hint-title {
					display: block;
					text-decoration: underline;
                }

                // hidden until .show-hint
                .hint-content,
                .close-hint {
                    display: none;
                }

                a {
                    display: inline-block;
                    margin: 10px;
                    padding: 7px 20px;
                    background-color: @Grey-9;
                    border: 1px solid @Grey-8;
                    border-radius: 3px;
                }
                .close-hint {
                    position: absolute;
                    top: 0;
                    right: 6px;
                    height: 40px;
                    width: 40px;
                    background: transparent url(../../../i/navigation/cross-small.svg) 50% 50% no-repeat;
                }

                &.show-hint {

                    // show buttons and close
                    .hint-content,
                    .close-hint {
                        display: block;
                    }
                }
            }
        }

        // login has buttons on mobile
        &.login {
            position: absolute;
            top: 60px;
            right: 0;
            width: 100%;

            .block-products {
                padding-left: 10px;

                // to fit buttons properly under icons
                & > li {
                    padding: 0 20px;
                }

                // list with links
                ul {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .mobile-link {
                        padding: 0;

                        a {
                            display: inline-block;
                            background-color: @White;
                            padding: 5px 10px;
                            border-radius: 3px;
                            border: 1px solid @Grey-8;
                            text-align: center;
                            font-size: 10px;
                        }
                    }
                }
            }
        }

    }

    // category specific styles
    .for-business .third-menu,
    .about-us .third-menu {

        .name {
            font-size: 16px;
        }
        .mobile-link {
            padding: 12px 18px 11px;
        }
    }
}
