.navigation-oo-19798 {

    .en-ww a:before {
        background-position: 0 0px !important;
    }

    .en-us a:before,
    .es-us a:before {
        background-position: 0 -384px !important;
    }

    .en-au a:before {
        background-position: 0 -288px !important;
    }

    .en-gb a:before {
        background-position: 0 -360px !important;
    }

    .en-ca a:before,
    .fr-ca a:before {
        background-position: 0 -312px !important;
    }

    .en-in a:before {
        background-position: 0 -624px !important;
    }

    .en-nz a:before {
        background-position: 0 -336px !important;
    }

    .en-za a:before {
        background-position: 0 -408px !important;
    }

    .en-id a:before {
        background-position: 0 -672px !important;
    }

    .en-my a:before {
        background-position: 0 -768px !important;
    }

    .en-ph a:before {
        background-position: 0 -1056px !important;
    }

    .tl-ph a:before {
        background-position: 0 -1056px !important;
    }

    .en-sg a:before {
        background-position: 0 -984px !important;
    }

    .el-gr a:before {
        background-position: 0 -240px !important;
    }

    .es-es a:before {
        background-position: 0 -504px !important;
    }

    .es-ww a:before {
        background-position: 0 -24px !important;
    }

    .es-mx a:before {
        background-position: 0 -528px !important;
    }

    .es-cu a:before {
        background-position: 0 -1240px !important;
    }

    .es-co a:before {
        background-position: 0 -480px !important;
    }

    .fa-ir a:before {
        background-position: 0 -1040px !important;
    }

    .it-it a:before {
        background-position: 0 -696px !important;
    }

    .de-de a:before {
        background-position: 0 -216px !important;
    }

    .pt-br a:before {
        background-position: 0 -888px !important;
    }

    .fr-fr a:before {
        background-position: 0 -576px !important;
    }

    .ja-jp a:before {
        background-position: 0 -720px !important;
    }

    .cs-cz a:before {
        background-position: 0 -120px !important;
    }

    .cs-sk a:before {
        background-position: 0 -144px !important;
    }

    .ru-ru a:before {
        background-position: 0 -960px !important;
    }

    .ru-kz a:before {
        background-position: 0 -936px !important;
    }

    .pl-pl a:before {
        background-position: 0 -864px !important;
    }

    .tr-tr a:before {
        background-position: 0 -1080px !important;
    }

    .ar-ww a:before {
        background-position: 0 -96px !important;
    }

    .ar-sa a:before {
        background-position: 0 -72px !important;
    }

    .ar-ae a:before,
    .en-ae a:before {
        background-position: 0 -264px !important;
    }

    .fr-ch a:before,
    .de-ch a:before {
        background-position: 0 -192px !important;
    }

    .pt-pt a:before {
        background-position: 0 -912px !important;
    }

    .en-eu a:before {
        background-position: 0 0px !important;
    }

    .nl-nl a:before {
        background-position: 0 -816px !important;
    }

    .nl-be a:before,
    .fr-be a:before {
        background-position: 0 -792px !important;
    }

    .no-no a:before {
        background-position: 0 -840px !important;
    }

    .he-il a:before {
        background-position: 0 -600px !important;
    }

    .be-by a:before {
        background-position: 0 -500px !important;
    }

    .ur-pk a:before {
        background-position: 0 -520px !important;
    }

    .da-dk a:before {
        background-position: 0 -168px !important;
    }

    .hr-hr a:before {
        background-position: 0 -580px !important;
    }

    .ca-es a:before {
        background-position: 0 -600px !important;
    }

    .bg-bg a:before {
        background-position: 0 -620px !important;
    }

    .vi-vn a:before {
        background-position: 0 -1128px !important;
    }

    .uk-ua a:before,
    .ru-ua a:before {
        background-position: 0 -1104px !important;
    }

    .sl-sl a:before {
        background-position: 0 -680px !important;
    }

    .sk-sk a:before {
        background-position: 0 -700px !important;
    }

    .sr-sr a:before {
        background-position: 0 -720px !important;
    }

    .sv-se a:before {
        background-position: 0 -1008px !important;
    }

    .ro-ro a:before {
        background-position: 0 -740px !important;
    }

    .ms-my a:before {
        background-position: 0 -768px !important;
    }

    .mk-mk a:before {
        background-position: 0 -780px !important;
    }

    .ko-kr a:before {
        background-position: 0 -744px !important;
    }

    .hu-hu a:before {
        background-position: 0 -648px !important;
    }

    .fi-fi a:before {
        background-position: 0 -552px !important;
    }

    .et-ee a:before {
        background-position: 0 -880px !important;
    }

    .id-id a:before {
        background-position: 0 -672px !important;
    }

    .th-th a:before {
        background-position: 0 -1032px !important;
    }

    .es-ar a:before {
        background-position: 0 -432px !important;
    }

    .es-cl a:before {
        background-position: 0 -456px !important;
    }

    .zh-cn a:before {
        background-position: 0 -1152px !important;
    }

    .zh-tw a:before {
        background-position: 0 -1176px !important;
    }

    .hi-in a:before {
        background-position: 0 -624px !important;
    }

}
