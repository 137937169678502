@import "../variables.less";

// navigation breakpoints
@desktop-start: 1056px;
@mobile-end: (@desktop-start - 1);


// media query
@navigation-desktop: ~"only screen and (min-width: @{desktop-start})";
@navigation-mobile: ~"only screen and (max-width: @{mobile-end})";

// body element for JS to detect desktop/mobile version
@media @navigation-desktop {
    body:before {
        content: 'navigation-desktop' !important;
        display: none !important;
    }
}

// styles include
@media @navigation-desktop {
    @import "navigation-oo-19798-desktop.less";
}

@media @navigation-mobile {
    @import "navigation-oo-19798-mobile.less";
}

@import "navigation-oo-19798-languages.less";


// redefining utility classes

// only desktop and mobile are used
.navigation-oo-19798 {

    // desktop
    @media @navigation-desktop {
        .mobile,
        .hidden-desktop {
            display: none !important;
        }
        .desktop {
            display: block !important;
        }
    }

    //mobile
    @media @navigation-mobile {

        .desktop,
        .hidden-mobile {
            display: none !important;
        }
        .mobile {
            display: block !important;
        }
    }
}

/* Logic */
// basic logic that is same for desktop and mobile version

.navigation-oo-19798 .second-menu,
.navigation-oo-19798 .third-menu { display: none; }

// second menu items are flex
.first-menu-for-home .second-menu.for-home,
.first-menu-for-business .second-menu.for-business,
.first-menu-for-partners .second-menu.for-partners,
.first-menu-about-us .second-menu.about-us,
.first-menu-regions .second-menu.regions,
.blogs-special-menu .second-menu.blogs {
    display: flex;
}

// third menu items are block
.second-menu-security .third-menu.security,
.second-menu-privacy .third-menu.privacy,
.second-menu-performance .third-menu.performance,
.second-menu-family .third-menu.family,
.second-menu-bundles .third-menu.bundles,
.second-menu-products .third-menu.products,
.second-menu-press-center .third-menu.press-center,
.second-menu-investors .third-menu.investors,
.second-menu-business-partners .third-menu.business-partners,
.second-menu-resources .third-menu.resources,
.second-menu-login .third-menu.login,
.blogs-special-menu .third-menu.topics {
    display: block;
}

/* Platform switching */
// using only hiding, because content is displayed in modes (block, flex...)

// win
.platform-windows .navigation-oo-19798 {
    .content-mac,
    .content-android,
    .content-ios {
        display: none;
    }
}
// mac
.platform-mac .navigation-oo-19798 {
    .content-windows,
    .content-android,
    .content-ios {
        display: none;
    }
}
// android
.platform-android .navigation-oo-19798 {
    .content-windows,
    .content-mac,
    .content-ios {
        display: none;
    }
}
// ios
.platform-ios .navigation-oo-19798 {
    .content-windows,
    .content-mac,
    .content-android {
        display: none;
    }
}

// General navigation styles
.navigation-oo-19798 {

    // Colors
    li, p, a {
        color: #767683;
    }
    .category,
    .subcategory {
        color: @Grey-0-new;
    }

    // Reset
    ul {
        margin: 0;
        padding: 0;

        li {
            padding: 0;
            list-style: none;
        }
    }

    // region selector

    // mixin for icon
    .region-selector-flag {
        background-color:
        transparent;
        background-image: url("../../../o/i/flags/flag-language-selector-v2.svg");
        background-repeat: no-repeat;
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 24px;
        width: 24px;
    }

    // displayed region
    .region .category::before {
        position: relative;
        top: -3px;
        margin-right: 10px;
        .region-selector-flag;
    }

    // region selector items
    .second-menu.regions {

        // flag before menu item
        .custom-regions li {
            font-size: 14px;
            padding-bottom: 9px;
            white-space: nowrap;

            a::before {
                margin-right: 10px;
                .region-selector-flag;
            }
        }
    }


    // media query handling exceptions

    // decrease T0 padding close to breakpoint - to better fit longer texts
    @media @navigation-desktop and (max-width: 1160px) {
        .first-menu ul li {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    // decrease T1 padding close to breakpoint - to better fit longer texts
    @media @navigation-desktop and (max-width: 1180px) {
        .second-menu .subcategory {
            padding-left: 7px;
            padding-right: 7px;
        }
    }

    // privacy has only two tiles in a row
    @media @navigation-desktop and (max-width: 1360px) {

        .third-menu.privacy {
            width: 522px;

            .block-products {
                flex-wrap: wrap;

                & > li {
                    width: 50%;
                    position: relative;

                    &:nth-child(2) {
                        border-right: none;
                    }
                    &:nth-child(1),
                    &:nth-child(2) {
                       &::after {
                           content: "";
                           display: block;
                           height: 1px;
                           width: 100%;
                           position: absolute;
                           left: 0;
                           bottom: -34px;
                           background-color: @Grey-8;
                       }
                    }
                }
            }
        }
    }

    @media @navigation-desktop and (max-width: 1156px) {
        .second-menu.regions .custom-regions {
			padding-left: 0;
			padding-right: 0;

				.area ul {
				padding-left: 10px;
				padding-right: 10px;
			}
		}
    }


    // second menu
}
