@import "../variables.less";


/* Logic */

// first level active state
.first-menu-for-home .for-home,
.first-menu-for-business .for-business,
.first-menu-for-partners .for-partners,
.first-menu-about-us .about-us,
.first-menu-regions .item.region {
    color: @Orange !important; // after the test will add .navigation to the path
}

// if page found in 2nd menu, highlight 2nd & 3rd menu, but do not open
.second-menu .isActive {
	&[data-second-menu] {
		.subcategory:after {
			content: url('../../../i/navigation/arrow-active.svg');
		}
	}

	.subcategory {
		color: @Orange !important;
	}

	.isActive {
		.name {
			color: @Orange;
		}
	}
}

.first-menu-for-business {
	.second-menu .isActive .isActive a {
		color: @Orange;
	}
}

// second level active/highlighted state (shadow aroundd the item)
.second-menu-security .subcategory.security,
.second-menu-privacy .subcategory.privacy,
.second-menu-performance .subcategory.performance,
.second-menu-family .subcategory.family,
.second-menu-bundles .subcategory.bundles,
.second-menu-products .subcategory.products,
.second-menu-business-partners .subcategory.business-partners,
.second-menu-resources .subcategory.resources,
.second-menu-press-center .subcategory.press-center,
.second-menu-investors .subcategory.investors,
.second-menu-login .subcategory.account,
.blogs-special-menu .item.blogs,
.first-menu-regions .item.region {
    color: @Orange !important; // after the test will add .navigation to the path
    border: 1px solid @Grey-8 !important;
    border-bottom: none !important;

    &::after {
        content: url(../../../i/navigation/arrow-active.svg);
    }
    &::before {
        position: absolute;
        bottom: -3px;
        left: 1px;
        z-index: 30;
        width: calc(100% - 2px);
        height: 6px;
        display: block;
        content: "";
        background-color: @White;
    }
}

// hover and acrive state over blog and regions
.blogs-special-menu .item.blogs,
.item.blogs.isActive,
.first-menu-regions .item.region {

    .category {
        color: @Orange !important;
    }
}

// search field opens
.navigation-search-opened .navigation-oo-19798 .search {

    &::after {
        background-image: url(../../../i/navigation/cross-medium.svg);
    }

    .search-holder {
        display: block;
        padding-left: 45px;

        input {
            width: 300px;
        }
    }
}

/* Navigation styles */
.navigation-oo-19798 {
    position: relative;
    background: @White;
    padding: 0 40px;
    min-height: 96px;
    box-shadow: 0 1px 3px 0 rgba(45, 54, 76, 0.12);
    border-bottom: 1px solid @Grey-8;
    display: flex;
    justify-content: space-between;

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    /* mixins */

    // third menu style
    .third-menu-style {
        background-color: @White;
        box-shadow: 0 1px 6px 0 rgba(45, 54, 76, 0.12);
        border: solid 1px @Grey-8;
    }
    // basics for navigation icon pseudo element
    .navigation-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
    }

    // Avast logo
    .avast-logo {
        flex: 0 0 150px;
        padding: 30px 0 0;
    }

    // navigation menus
    nav {
        flex: 0 1 100%;
    }

    .first-menu,
    .second-menu {
        display: flex;
        justify-content: space-between;

        // about-us should have all items on the left (the only exception)
        &.about-us {
            justify-content: flex-start;
        }

        ul {

            li {
                display: inline-block;
                cursor: pointer;
                display: block;
                position: relative; // for absolute positioning of third menu
                color: @Grey-0-new;
            }
        }
    }

    /* ### first menu */
    // top row of header
    .first-menu {

        ul {
            li {
                height: 48px;
                padding: 16px 16px 0;
                border: 1px solid transparent;

                &:not(.region) {
                    // first level links are dark grey and orange on hover
                    .category {
                        color: @Grey-0-new;

                        &:hover {
                            text-decoration: none;
                            color: @Orange;
                        }
                    }
                }

            }
        }

        .item.blogs {

            &:hover .category {
                color: @Orange !important;
            }
        }
	}

    /* ### second menu */
    // bottom row of header
    // items thatopens third level has arrow and active state
    .second-menu {

         // temporary, will be prolly replaced by other element
        .subcategory {
            display: block;
            font-size: 14px;
            font-weight: 400;
            height: 47px;
            padding: 16px 16px 0;
            border: 1px solid @White;
            border-bottom: none;
        }

        // only added to override styles in /business page - can be removed when fixed
        &.for-business {

            .products {
            // only added because styles in /business
            // all links except hint not decorated

                *:not(.hint) {

                    a {
                        &:link {
                            text-decoration: none;
                        }
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

            }
        }

        // special kind of second menu that looks like 3rd menu products
        &.blogs {
            position: absolute;
            z-index: 20;
            right: 100px;
            top: 49px;
            padding: 34px 0;
            .third-menu-style;

            & > li {
                padding: 0 34px;
                max-width: 260px;  // otherwise text makes them too wide
                text-align: center;

                &:not(:last-child) {
                    border-right: 1px solid @Grey-8;
                }
                &:hover {
                    .txt-blogs-title {
                        color: @Orange;
                    }
                }
                a:hover {
                    text-decoration: none;
                }
                .txt-blogs-title {
                    display: block;
                    white-space: nowrap;
                    font-size: 18px;
                    scroll-padding-bottom: 20px;
                }

                .txt-blogs-description {
                    display: block;
                    font-size: 14px;
                }

            }
        }

        // region selector
        &.regions {
            position: absolute;
            z-index: 20;
            right: 20px;
            top: 49px;
            width: calc(100% - 40px);
            padding: 60px 0 15px;
            .third-menu-style;

            & > li {
                width: 100%;
            }

            .custom-regions {
                position: relative;
                display: flex;
                justify-content: space-around;
                padding-left: 40px;
                padding-right: 40px;

                &::after {
                    display: block;
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 65px;
                    border-top: 1px solid @Grey-9;
                }

                .area {
                    position: relative;
                    padding-bottom: 90px;

                    .subcategory {
                        font-size: 16px;
                        font-weight: bold;
                        padding: 0 0 30px;
                        height: auto;

                        &::after {
                            display: none;
                        }
                    }

                    ul {
                        float: left;
                        padding-right: 30px;

                        // main region at the bottom
                        &.other {
                            float: none;
                            position: absolute;
                            left: 0;
                            bottom: 7px;
                            z-index: 2000;
                        }
                    }
                }


                & > div::after {
                    display: block;
                    content: "";
                    clear: both;
                }
            }
        }

    }

    /* ### third menu */
    // tiles with products in home section
    // lists with links in business

    .third-menu {
        position: absolute;
        left: 0;
        top: 48px;
        z-index: 20;
        .third-menu-style;

        // do not underline links on home products
        &.security,
        &.privacy,
		&.performance,
		&.family,
		&.bundles {

            a:hover {
                text-decoration: none;
            }
        }

        // login must be aligned to right
        &.login {

            left: auto;
            right: 0;
        }

        // third menu - products row
        .block-products {
            display: flex;


            & > li {
                margin: 34px 0;
                padding: 0 34px;
                min-width: 260px;  // otherwise it is defined by the lenght of product name
                max-width: 300px; // to prevent too long boxes in IE11
                font-size: 14px;

                &:not(:last-child) {
                    border-right: 1px solid @Grey-8;
                }

                // product items
                .name {
                    display: block;
                    white-space: nowrap;
                    padding-bottom: 14px;
                }

                .logo {
                    margin-right: 7px;
                    vertical-align: middle;
                }

                // operation system logos
                // styles for latte template
                .os {
                    display: block;
                    padding-bottom: 14px;

                    img {
                        margin: 0 5px;
                        display: none;
                    }

                    &.win img:nth-of-type(1) {
                        display: inline-block;
                    }
                    &.mac img:nth-of-type(2) {
                        display: inline-block;
                    }
                    &.android img:nth-of-type(3) {
                        display: inline-block;
                    }
                    &.ios img:nth-of-type(4) {
                        display: inline-block;
                    }
                }
                .description {
                    font-size: 14px;
                }

                // list inside the main sections (used on business)
                & > ul {
                    // rewriting to flex to let them have lenght based on content (because of arrows at the end
                    // arrows has been removed
                    display: flex;
                    flex-direction: column;
                    align-items: stretch; // can ve on he whole lenght because arrows removed

                    li {
                        text-align: left;

                        &:not(:last-child) {
                            padding-bottom: 20px;
                        }

                        a {
                            display: block;

                            &:hover {
                                color: @Grey-0-new;
                            }
                        }

                        /* removing arrow
                        &::after {
                            content: url(../../../i/navigation/arrow-item.svg);
                            .navigation-icon;
                            margin-left: 10px;
                            vertical-align: middle;
                        }
                        */
                    }
                }

            }
        }

        // third menu - txt block (title with description below)
        // specific element used so far only in business
        .txt-block-title,
        .txt-block-description {
            display: block;
            max-width: 200px;
        }
        .txt-block-title {
            font-size: 18px;
            padding-bottom: 7px;
        }
        .txt-block-description {
            font-size: 12px;
            white-space: normal;
        }

        // third menu - hint (sentence below the products)
        // used both in home and business
        .hint {
            padding: 14px 34px;
            border-top: 1px solid @Grey-8;
            font-size: 14px;
            cursor: default;

            a,
            a:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    // specific styles for each section

    // for home
    .for-home .third-menu {

        li {
            text-align: center;

            .name {
                font-size: 20px;
                line-height: 28px;
            }
            &:hover {
                .name {
                    color: @Orange;
                }
            }
        }
    }
    // for business + about us
    .for-business .third-menu,
    .about-us .third-menu {

        li {
            text-align: left;

            .name {
                font-size: 16px;
                font-weight: bold;
                line-height: 28px;
            }
        }
        .hint {

            a {
                display: inline-block;
                margin-right: 20px;
            }
        }
    }

    .side {
        display: flex;
        padding-bottom: 0;
    }

    .item {
        display: inline-block;
        cursor: pointer;
        display: block;
        padding: 10px;
    }

    /* icons */

    // collapsible item
    .arrow::after {
        content: url(../../../i/navigation/arrow-inactive.svg);
        .navigation-icon;
        margin-left: 5px;
        vertical-align: middle;
    }

    // account icon
    .account {

        img {
            margin-right: 5px;
            vertical-align: middle;
        }
    }

    // search

    // consists from two layers - open/close search icon and input field below
    .search {
        position: relative;

        span {
            display: none;
        }

        &::before {
            content: "";
            .navigation-icon;
            margin-right: 5px;
            vertical-align: -8px;
        }

        // search / close icon
        &::after {
            content: "";
            .navigation-icon;
            position: absolute;
            left: 12px;
            bottom: 4px;
            z-index: 20; // middle level
            width: 30px;
            height: 30px;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-image: url(../../../i/navigation/search.svg);
        }

        // checkbox to open/close menu
        .search-toggle {
            position: absolute;
            bottom: 0px;
            left: 0;
            z-index: 30; // highest level (must be clickable)
            cursor: pointer;
            display: block;
            width: 45px;
            height: 35px;
            opacity: 0; // make it invisible but working
        }

        // input field
        .search-holder {
            display: none;
            position: absolute;
            z-index: 5; // lowest level
            bottom: -8px;
            right: 0;
            box-shadow: 0 1px 6px 0 rgba(45, 54, 76, 0.12);
            border: solid 1px @Grey-8;
            padding-right: 35px;
            background: @White url(../../../i/navigation/search-hint.png) 15px 50% no-repeat;

            input {
                border: none;
                //line-height: 50px;
                padding: 15px 0;
                width: 0;
                background-color: transparent
            }
        }
    }

    // region - last item in the top menu
    .region {
        position: relative;

        &::before {
            display: block;
            content: "";
            position: absolute;
            left: 0px;
            bottom: 9px;
            width: 1px;
            height: 20px;
            background-color: @Grey-6;
        }

        a:hover {
            text-decoration: none;
        }
    }
}
